import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get, set } from "lodash";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { congrats } from "./congrats.js";
import { congratsGenni } from "./congrats-genni.js";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { CSVLink, CSVDownload } from "react-csv";
import {
  getCompetition,
  listAllCompetitions,
  listWinnerss,
  listPayments,
  listCompetitions,
} from "../../graphql/queries";
import {
  updatePost,
  updateCompetition,
  createWinners,
  updateWinners,
  createPayment,
  updatePayment,
} from "../../graphql/mutations";
import { order } from "@amcharts/amcharts4/.internal/core/utils/Number.js";
//CountDown

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function hasDuplicates(array) {
  console.log("duplicates array", array);
  var valuesSoFar = Object.create(null);
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (value in valuesSoFar) {
      return true;
    }
    valuesSoFar[value] = true;
  }
  return false;
}

const findDuplicates = (arr) => {
  let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

function FinishCompetition() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [competitions, setCompetitions] = useState([]);
  const [winners, setWinners] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [newWinners, setNewWinners] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [selectedCompetitionName, setSelectedCompetitionName] = useState();
  const [orderedWinnersArr, setOrderedWinnersArr] = useState();
  const [orderedCreativeWinnersArr, setOrderedCreativeWinnersArr] = useState();
  const steps = ["Notify Winners", "Pay Winners", "Mark As Completed"];
  const fetchCompetitions = async () => {
    let nextToken = null;
    let allItems = [];

    do {
      const apiData = await API.graphql({
        query: listCompetitions,
        variables: {
          limit: 100000,
          nextToken: nextToken,
          filter: { endDate: { gt: 1724162241000 } },
        },
      });

      const items = apiData.data.listCompetitions.items;

      // Filter out deleted items
      const filteredItems = items.filter((e) => e._deleted !== true);

      // Accumulate all filtered items
      allItems = allItems.concat(filteredItems);

      // Update nextToken
      nextToken = apiData.data.listCompetitions.nextToken;
    } while (nextToken); // Continue while there is a nextToken

    // Set the competitions state with all accumulated items
    setCompetitions(allItems);
  };

  const [csvData, setCSVData] = useState([
    [
      "Email/Phone",
      "Amount",
      "Currency code",
      "Reference ID (optional)",
      "Note to recipient",
      "Recipient wallet",
      "Social Feed Privacy (optional)",
      "Holler URL (deprecated)",
      "Logo URL (optional)",
    ],
  ]);

  function compare(a, b) {
    if (a.creator < b.creator) {
      return -1;
    }
    if (a.creator > b.creator) {
      return 1;
    }
    return 0;
  }

  function compareOrganizer(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  function compareLikes(a, b) {
    if (a.likes > b.likes) {
      return -1;
    }
    if (a.likes < b.likes) {
      return 1;
    }
    return 0;
  }

  function compareCreativity(a, b) {
    if (a.creativtiyScore > b.creativtiyScore) {
      return -1;
    }
    if (a.creativtiyScore < b.creativtiyScore) {
      return 1;
    }

    return 0;
  }

  const fetchWinners = async () => {
    const myWinners = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listWinnerss,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
        },
      });
      const myWinnersTemp = get(apiData, ["data", "listWinnerss", "items"], []);
      myWinnersTemp.map((e) => {
        if (e._deleted !== true) {
          myWinners.push(e);
        }
      });

      nToken = apiData.data.listWinnerss.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    setWinners(
      myWinners
        .filter(function (e) {
          return e._deleted !== true;
        })
        .sort(compare)
    );
  };

  const fetchIndividualCompetition = async () => {
    const apiData = await API.graphql({
      query: getCompetition,
      variables: { id: currentId },
    });
    setSelectedCompetition(apiData.data.getCompetition);
    setSelectedCompetitionName(apiData.data.getCompetition.name);
    const creatorObj = {};
    const postsArr =
      apiData && apiData.data.getCompetition.posts.items
        ? apiData.data.getCompetition.posts.items
            .filter(function (e) {
              const creatorId = e.creator.replace(" ", "") + e.platform;
              if (!e._deleted) {
                if (!creatorObj[creatorId]) {
                  creatorObj[creatorId] = {
                    creator: e.creator,
                    platform: e.platform,
                    email: e.email,
                    likes: e.likes,
                    creativityScore: e.creativityScore,
                    source: e.source === "genni" ? "genni" : "",
                    posts: 1,
                    link: e.link,
                  };
                } else {
                  const tempLikes = creatorObj[creatorId]["likes"];
                  const tempCreativityScore =
                    creatorObj[creatorId]["creativityScore"];
                  const tempPosts = creatorObj[creatorId]["posts"];
                  const tempLink =
                    tempLikes < e.likes
                      ? e.link
                      : creatorObj[creatorId]["link"];
                  creatorObj[creatorId] = {
                    creator: e.creator,
                    platform: e.platform,
                    source: e.source === "genni" ? "genni" : "",
                    email: e.email,
                    likes: tempLikes + e.likes,
                    creativityScore: tempCreativityScore + e.creativityScore,
                    posts: tempPosts + 1,
                    link: tempLink,
                  };
                }
              }

              return e._deleted !== true;
            })
            .sort(compareLikes)
        : [];
    const creatorArr = Object.values(creatorObj).sort(compareLikes);
    const creativityArr = manualSort(Object.values(creatorObj));
    setOrderedWinnersArr(creatorArr);
    setOrderedCreativeWinnersArr(creativityArr);
  };

  const changeId = (e) => {
    setCurrentId(e.target.value);
  };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  function roundToNearestHundredth(num) {
    // Multiply the number by 100 to shift the decimal two places to the right
    let multipliedNum = num * 100;

    // Round the multiplied number to the nearest integer using Math.round()
    let roundedNum = Math.round(multipliedNum);

    // Divide the rounded number by 100 to shift the decimal two places to the left
    let result = roundedNum / 100;

    return result;
  }

  // console.log("new winners",newWinners, newWinners.map(object => object.display));

  const hasBannedUsers = (wD) => {
    let hasBannedUsers = [];
    wD.map((winner) => {
      if (
        winners.filter((e) => e.email === winner.email && e.isBanned).length > 0
      ) {
        hasBannedUsers.push(winner.email);
      }
    });
    return hasBannedUsers;
  };
  const submit = () => {
    const winnersDisplays = newWinners.map((object) => object.creator);
    const winnersDuplications = newWinners.map(
      (object) => `${object.creator} - ${object.platform}`
    );
    if (
      !hasDuplicates(winnersDuplications) &&
      hasBannedUsers(newWinners).length === 0
    ) {
      setActiveStep(1);
      newWinners.map(async (winner, i) => {
        const isGenni =
          selectedCompetition.posts.items.filter(
            (e) => e.source && winner.email === e.email && e.source === "genni"
          ).length > 0;

          if (isGenni) {
          await congratsGenni(
            winner.email,
            winner.winnings,
            winner.prizeName,
            selectedCompetitionName
          );
          fetch(
            `https://songfluencer.me/preffy/get_paypal_email.cfm?Token=779DEA53-9C6E-11B1-1526D7FE288AF8F4&Task=GetPaymentEmail&UserEmail=${winner.email}`,
            { method: "GET" }
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw res;
              //const jsonRes = JSON.parse(res);
            })
            .then(async (data) => {
              const paymentVs = {
                input: {
                  CompetitionID: selectedCompetition.id,
                  ContactEmail: winner.email,
                  PaymentEmail: data.PaymentEmail,
                  Amount: isGenni
                    ? parseInt(winner.winnings)
                    : roundToNearestHundredth(parseInt(winner.winnings) * 0.9),
                  Status: "To Be Paid",
                  Source: isGenni ? "Genni" : "Preffy",
                },
              };

              const cPayment = await API.graphql(
                graphqlOperation(createPayment, paymentVs)
              );
            });
        } else {
          await congrats(
            winner.email,
            winner.winnings,
            winner.prizeName,
            selectedCompetitionName
          );
          const paymentVs = {
            input: {
              CompetitionID: selectedCompetition.id,
              ContactEmail: winner.email,
              PaymentEmail: winner.paypal,
              Amount: isGenni
                ? parseInt(winner.winnings)
                : roundToNearestHundredth(parseInt(winner.winnings) * 0.9),
              Status: "To Be Paid",
              Source: isGenni ? "Genni" : "Preffy",
            },
          };

          const cPayment = await API.graphql(
            graphqlOperation(createPayment, paymentVs)
          );
        }

        // if (winners.filter((e) => e.email === winner.email).length > 0) {
        //   winners.map(async (e) => {
        //     if (e.email === winner.email){
        //       const newBalance = parseInt(e.balance) + parseInt(winner.winnings);
        //       const vs = {
        //         input: {
        //           id: e.id,
        //           email: winner.email,
        //           balance: newBalance,
        //           _version: e._version,
        //         },
        //       };
        //       const updateWinner = await API.graphql(
        //         graphqlOperation(updateWinners, vs)
        //       );
        //     }
        //   })
        //   //update

        // } else {
        //   //add winner
        //   const vs = {
        //     input: {
        //       email: winner.email,
        //       balance: winner.winnings,
        //     },
        //   };
        //   const createWinner = await API.graphql(
        //     graphqlOperation(createWinners, vs)
        //   );
        // }
      });
    } else {
      if (hasBannedUsers(newWinners).length > 0) {
        alert(
          `Please Removed Banned Users: ${hasBannedUsers(
            newWinners
          ).toString()}`
        );
      } else {
        alert(`Duplicates found: ${findDuplicates(winnersDisplays)}`);
      }
    }
  };

  const [payments, setPayments] = useState();
  const [isDoneLoadingCSV, setIsDoneLoadingCSV] = useState(false);
  const fetchPayments = async () => {
    const myPayments = [];
    let done = false;
    let nToken = false;
    while (!done) {
      const apiData = await API.graphql({
        query: listPayments,
        variables: {
          limit: 1000,
          nextToken: !nToken ? null : nToken,
          filter: {
            Status: { eq: "To Be Paid" },
            CompetitionID: { eq: selectedCompetition.id },
          },
        },
      });
      const myPaymentsTemp = get(
        apiData,
        ["data", "listPayments", "items"],
        []
      );
      myPaymentsTemp.map((e) => {
        if (e._deleted !== true) {
          myPayments.push(e);
        }
      });

      nToken = apiData.data.listPayments.nextToken;
      if (nToken === null) {
        done = true;
      }
    }
    if (done && nToken === null) {
      setIsLoadingPayments(false);
      setPayments(
        myPayments.filter(function (e) {
          return e._deleted !== true;
        })
      );
    }
  };

  const markAsCompleted = () => {
    setIsLoadingPayments(true);
    payments.map((e, n) => {
      csvData.map(async (a, i) => {
        if (i > 0 && a[0] === e.PaymentEmail) {
          const vs = {
            input: {
              id: e.id,
              Status: "Paid",
              _version: e._version,
            },
          };
          const upPayment = await API.graphql(
            graphqlOperation(updatePayment, vs)
          );
        }
      });
    });
    setTimeout(() => {
      setIsLoadingPayments(false);
    }, "10000");
  };

  useEffect(() => {
    fetchWinners();
    fetchCompetitions();
  }, []);
  useEffect(() => {
    fetchIndividualCompetition();
  }, [currentId]);

  let prizeArr = [];
  if (selectedCompetition) {
    selectedCompetition.prizes.map((e, i) => {
      if (e.includes("-") && !e.includes("Followers")) {
        let label = e.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = e.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = e.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const keyValues = e.split(": $");
          const prizeAmount = parseInt(keyValues[1]);
          for (
            let index = parseFloat(rangeLowVal);
            index <= parseFloat(rangeHighVal);
            index++
          ) {
            prizeArr.push(`${ordinal_suffix_of(index)} Place: $${prizeAmount}`);
          }
        }
      } else if (e.includes("Top")) {
        let label = e.split("$")[0];
        const amountOfWinners = parseInt(label.split(" ")[1]);
        const keyValues = e.split(": $");

        const prizeAmount = parseInt(keyValues[1]);
        for (let i = 1; i <= amountOfWinners; i++) {
          prizeArr.push(`${amountOfWinners} Most Creative: $${prizeAmount}`);
        }
      } else {
        prizeArr.push(e);
      }
    });
  }
  const handlePostChange = (e) => {
    const copyOfNewWinners = newWinners;
    const targetValue = JSON.parse(e.target.value);
    const index = targetValue.ind;
    copyOfNewWinners[index] = targetValue;
    setNewWinners(copyOfNewWinners);
  };
  function manualSort(array) {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = 0; j < array.length - i - 1; j++) {
        // Compare by creativityScore
        if (
          array[j].creativityScore < array[j + 1].creativityScore ||
          (array[j].creativityScore === array[j + 1].creativityScore &&
            array[j].likes < array[j + 1].likes)
        ) {
          // Swap the elements
          let temp = array[j];
          array[j] = array[j + 1];
          array[j + 1] = temp;
        }
      }
    }
    return array;
  }

  const usedIndices = new Set();
  const usedDisplayIndices = new Set();
  const getUniqueValue = (arr, usedIndices, i, isDisplay = false) => {
    const uniqueKey = `${arr[i].creator} - ${arr[i].source} - ${arr[i].platform}`;
    if (!usedIndices.has(uniqueKey)) {
      usedIndices.add(uniqueKey);
      return `${arr[i].creator} - ${arr[i].email} - ${arr[i].source} - ${arr[i].platform}`;
    }
    return ""; 
  };
  
  // Fallback logic for the second array (Creative Winners)
  const getFallbackValue = (arr, usedIndices) => {
    for (let j = 0; j < arr.length; j++) {
      const uniqueKey = `${arr[j].creator} - ${arr[j].source} - ${arr[j].platform}`;
      if (!usedIndices.has(uniqueKey) && arr[j].creativityScore >= 100) {
        usedIndices.add(uniqueKey);
        return `${arr[j].creator} - ${arr[j].email} - ${arr[j].source} - ${arr[j].platform}`;
      }
    }
    return ""; 
  };
  

  const returnStep = () => {
    if (activeStep === 0) {
      return (
        <>
          <Label for="exampleSelect" sm={12} xl={12}>
            Select Competition
          </Label>
          <Col sm={4}>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={currentId ? currentId : "-"}
              onChange={changeId}
            >
              <option value="-" disabled>
                -
              </option>
              {competitions.sort(compareOrganizer).map((e, i) => {
                return (
                  <option key={i} value={e.id}>
                    {e.name} - {e.organizer}
                  </option>
                );
              })}
            </Input>
          </Col>
          {prizeArr.map((prize, i) => {
            if (orderedWinnersArr && orderedCreativeWinnersArr) {
              const keyValues = prize.split(": $");
              const prizeKey = keyValues[0];
              const prizeAmount = parseInt(keyValues[1]);

              const defaultVal = prize.includes("Place") && orderedWinnersArr[i]
              ? getUniqueValue(orderedWinnersArr, usedIndices, i)
              : getFallbackValue(orderedCreativeWinnersArr, usedIndices);

              const defaultDisplay = prize.includes("Place") && orderedWinnersArr[i]
              ? getUniqueValue(orderedWinnersArr, usedDisplayIndices, i, true)
              : getFallbackValue(orderedCreativeWinnersArr, usedDisplayIndices);
              const copyWinners = newWinners;
              
              const index = winners.findIndex((object) => {
               
                if (!object._deleted) {
                  
                  if (defaultVal.includes(object.email) && !defaultVal.includes(" - genni") && object.email) {
                    
                    return defaultVal.includes(object.email);
                  }
                }
              });
              


              if (defaultVal) {
                copyWinners[i] = {
                  email: defaultVal && defaultVal.split(" - ")[1],
                  paypal: index >= 0 ? winners[index].paypal : "",
                  winnings: prizeAmount,
                  prizeName: prizeKey,
                  platform: defaultVal && defaultVal.split(" - ")[3],
                  creator: defaultVal && defaultVal.split(" - ")[0],
                };
                if (!newWinners[i]) {
                  
                  setNewWinners(copyWinners);
                }
              }


              const creatorsEmails = [];

              return (
                <>
                  <Label for="prizeSelect" sm={12} xl={12}>
                    {prize}
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="select"
                      id="prizeSelect"
                      onChange={handlePostChange}
                    >
                      <option value={defaultVal}>{defaultDisplay}</option>
                      {selectedCompetition.posts.items
                        .sort(compare)
                        .map((post, n) => {
                          const source = post.source ? post.source : "";
                          const index = winners.findIndex((object) => {
                            if (!object._deleted) {
                              return object.email === post.email;
                            }
                          });

                          const ppal = index >= 0 ? winners[index].paypal : "";
                          const sVal = {
                            email: post.email,
                            paypal: ppal ? ppal : "",
                            winnings: prizeAmount,
                            prizeName: prizeKey,
                            ind: i,
                            creator: post.creator,
                          };
                          if (
                            !creatorsEmails.includes(
                              post.creator + "-" + post.email + "-" + source
                            ) &&
                            newWinners.filter((e) => e.email === post.email)
                              .length === 0 &&
                            post._deleted !== true
                          ) {
                            creatorsEmails.push(
                              post.creator + "-" + post.email + "-" + source
                            );
                            return (
                              <option
                                key={n}
                                value={JSON.stringify(sVal)}
                              >{`${post.creator} - ${post.email} - ${source}`}</option>
                            );
                          }
                        })}
                    </Input>
                  </Col>
                </>
              );
            }
          })}
          <br />
          <Button className="full-width" onClick={submit}>
            {isLoading ? (
              <>
                <SpinnerCircular color="#fff" size={30} />
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </>
      );
    } else if (activeStep === 1) {
      if (!isDoneLoadingCSV) {
        const newCSVData = csvData;
        const fetchPromises = []; // Array to store the fetch promises
        newWinners.forEach((winner, i) => {
          
          const isGenni =
            selectedCompetition.posts.items.filter(
              (e) =>
                e.source && winner.email === e.email && e.source === "genni"
            ).length > 0;
            if (!winner.paypal && !isGenni){
              console.log("winner",winner,isGenni);

            }

          if (isGenni) {
            const fetchPromise = fetch(
              `https://songfluencer.me/preffy/get_paypal_email.cfm?Token=779DEA53-9C6E-11B1-1526D7FE288AF8F4&Task=GetPaymentEmail&UserEmail=${encodeURIComponent(winner.email)}`,
              { method: "GET" }
            )
              .then((response) => response.json())
              .then((formattedResponse) => {
                if (formattedResponse.PaymentEmail) {
                  newCSVData.push([
                    formattedResponse.PaymentEmail,
                    parseInt(winner.winnings),
                    "USD",
                    i,
                    `preffy id:${selectedCompetition.id}`,
                  ]);
                }
              })
              .catch((error) => {
                console.error(
                  `Error fetching payment email for ${winner.email}:`,
                  error
                );
              });

            fetchPromises.push(fetchPromise);
          } else {

            newCSVData.push([
              winner.paypal,
              roundToNearestHundredth(parseInt(winner.winnings) * 0.9),
              "USD",
              i,
              `preffy id:${selectedCompetition.id}`,
            ]);
          }
        });

        Promise.all(fetchPromises)
          .then(() => {
            setIsDoneLoadingCSV(true);
            setCSVData(newCSVData);
          })
          .catch((error) => {
            console.error("Error while waiting for fetch promises:", error);
          });
      }

      //   newWinners.map(async (winner,i)=>{
      //       const isGenni = selectedCompetition.posts.items.filter(e => e.source && winner.email === e.email && e.source === "genni").length > 0;
      //       if (isGenni) {
      //         await fetch(
      //           `https://songfluencer.me/preffy/get_paypal_email.cfm?Token=779DEA53-9C6E-11B1-1526D7FE288AF8F4&Task=GetPaymentEmail&UserEmail=${winner.email}`,
      //           { method: "GET" }
      //         )
      //           .then((res) => {
      //             if (res.ok) {
      //               return res.json();
      //             }
      //             throw res;
      //             //const jsonRes = JSON.parse(res);
      //           })
      //           .then(async (data) => {
      //             console.log("629");
      //             setCSVData(prevArrayOfArrays => [...prevArrayOfArrays, [data.PaymentEmail, parseInt(winner.winnings), "USD", i, `preffy id:${selectedCompetition.id}`]])
      //           });
      //       } else {
      //         csvData.push([winner.paypal, roundToNearestHundredth(parseInt(winner.winnings) * .9), "USD", i, `preffy id:${selectedCompetition.id}`])
      //       }

      //       if (i === newWinners.length - 1) {
      //         setIsDoneLoadingCSV(true);
      //       }

      // })

      return (
        <>
          {isDoneLoadingCSV ? (
            <CSVLink
              onClick={() => setActiveStep(2)}
              data={csvData}
              className="btn btn-primary"
              filename={`${
                selectedCompetition && selectedCompetition.name
              }.csv`}
            >
              Download CSV
            </CSVLink>
          ) : (
            <SpinnerCircular color="#fff" size={30} />
          )}
        </>
      );
    } else if (activeStep === 2) {
      if (!payments) {
        fetchPayments();
      }

      return (
        <>
          {isLoadingPayments ? (
            <SpinnerCircular color="#fff" size={30} />
          ) : (
            <div
              className="btn btn-primary"
              onClick={() => {
                markAsCompleted();
              }}
            >
              Mark As Completed
            </div>
          )}
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <br />
      <br />
      <section className="container pb-4 bg-white mt-5 p-4 shadow" id="home">
        <h3>
          Finish Competition - {selectedCompetition && selectedCompetition.name}
        </h3>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {returnStep()}
      </section>
    </React.Fragment>
  );
}

export default FinishCompetition;
