/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompetition = /* GraphQL */ `
  query GetCompetition($id: ID!) {
    getCompetition(id: $id) {
      id
      image
      name
      organizer
      prize
      audio
      isActive
      startDate
      endDate
      lastUpdated
      isClosed
      organizerEmail
      creativeDirection
      isDirect
      notes
      continents
      countries
      platforms
      prizes
      tiktokSound
      instagramSound
      _version
      tiktok
      competitionType
      instagram
      invoiceAmount
      _deleted
      spotify
      posts(limit: 50000000) {
        items {
          id
          link
          email
          creator
          platform
          likes
          bonusLikes
          postId
          creativityScore
          source
          views
          createdAt
          country
          updatedAt
          _version
          _deleted
          referrals {
            items {
              id
              referringLink
              postId
              _version
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompetitions = /* GraphQL */ `
  query ListCompetitions(
    $filter: ModelCompetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _deleted
        _version
        name
        organizerEmail
        organizer
        image
        startDate
        endDate
        lastUpdated
        isActive
        prizes
        isClosed
        audio
        prize
        hasEnded
        campaignID
        createdAt
        isConfirmed
        posts(limit: 1000000) {
          items {
            id
            link
            platform
            _deleted
            creator
            email
            postId
            creativityScore
            likes
            _version
            createdAt
          }
        }
      }
      nextToken
    }
  }
`;

export const listAllCompetitions = /* GraphQL */ `
  query listAllCompetitions($limit: Int) {
    listCompetitions(limit: $limit) {
      items {
        id
        _deleted
        _version
        name
        organizerEmail
        organizer
        image
        startDate
        endDate
        lastUpdated
        isActive
        prizes
        isClosed
        audio
        prize
        hasEnded
        campaignID
        createdAt
        isConfirmed
        posts(limit: 1000000) {
          items {
            id
            link
            platform
            _deleted
            creator
            email
            _version
            createdAt

          }
        }
      }
    }
  }
`;

export const listAllWinners = /* GraphQL */ `
  query listWinners {
    listWinnerss(limit: 1000000) {
      items {
        id
        email
        paypal
        isUnsubscribed
        isVetted
        isBanned
        balance
        _deleted
        _version
      }
    }
  }
`;



export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      link
      email
      postId
      competition {
        id
        name
        posts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        link
        email
        creator
        likes
        platform
        postId
        competition {
          id
          name
          createdAt
          updatedAt
          posts(limit: 1000000) {
            items {
              id
              creator
              email
              platform
              likes
            }
          }
          hasEnded
          _deleted
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getWinners = /* GraphQL */ `
  query GetWinners($id: ID!) {
    getWinners(id: $id) {
      id
      email
      balance
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

export const listWinnerss = /* GraphQL */ `
  query ListWinnerss(
    $filter: ModelWinnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWinnerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        paypal
        isBanned
        isUnsubscribed
        isVetted
        balance
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listAllPromotions = /* GraphQL */ `
  query listAllPromotions($limit: Int) {
    listPromotions(limit: $limit) {
      items {
        id
        _deleted
        _version
        Name
        startDate
        endDate
        isActive
        Amount
        creatorEmails
        RemainingBalance
        CompetitionID
      }
    }
  }
`;

export const listPayments = /* GraphQL */ `
  query listPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _deleted
        _version
        createdAt
        CompetitionID
        ContactEmail
        ContactID
        VendorID
        Source
        PaymentEmail
        Amount
        Status
      }
      nextToken
    }
  }
`;

export const listAllPayments = /* GraphQL */ `
  query listAllPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        _deleted
        _version
        createdAt
        CompetitionID
        ContactEmail
        ContactID
        VendorID
        Source
        PaymentEmail
        Amount
        Status
      }
      nextToken
    }
  }
`;